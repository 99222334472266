import React, { useState } from 'react';
import { ButtonGroup, Button, Spinner } from 'reactstrap';
import Form from '@rjsf/core';
import gqlTag from 'graphql-tag';
import { useMutation } from '@apollo/client';

const FORGOT_PASSWORD = gqlTag`
  mutation ForgotPassword($email: String!) {
    forgotPassword(input: { email: $email }) {
      clientMutationId
    }
  }
`;

const forgotPasswordSchema = {
  title: 'Forgot your password?',
  type: 'object',
  required: ['email'],
  properties: {
    email: {
      type: 'string',
      minLength: 1,
      maxLength: 300,
      title: 'Email',
      format: 'email',
    },
  },
};

const ForgotPassword = ({ setForgotPassword }) => {
  const [emailWasSent, setEmailWasSent] = useState(false);

  const [forgotPassword, { loading, error }] = useMutation(FORGOT_PASSWORD, {
    onCompleted: () => {
      setEmailWasSent(true);
    },
  });

  const onSubmit = data => {
    forgotPassword({
      variables: { ...data.formData },
    }).catch(e => console.error(e));
  };

  return (
    <>
      {error && <p>{error.message || 'Uncaught error'}</p>}
      {!error && !emailWasSent && (
        <p>
          Enter your email address and we'll send you a link to reset your
          password.
        </p>
      )}
      <hr />
      {emailWasSent ? (
        <>
          <h4 className="mb-4">
            Link to reset your password was sent. Check your email.
          </h4>
          <ButtonGroup className="mr-4">
            <Button onClick={() => setForgotPassword(false)} outline>
              Login
            </Button>
          </ButtonGroup>
        </>
      ) : (
        <Form
          schema={forgotPasswordSchema}
          showErrorList={false}
          disabled={loading}
          onSubmit={onSubmit}
        >
          <ButtonGroup className="mr-4">
            <Button disabled={loading} type="submit">
              {loading && (
                <>
                  <Spinner size="sm" />{' '}
                </>
              )}
              Submit
            </Button>
          </ButtonGroup>
          <ButtonGroup>
            <Button
              onClick={() => setForgotPassword(false)}
              disabled={loading}
              outline
            >
              Cancel
            </Button>
          </ButtonGroup>
        </Form>
      )}
    </>
  );
};

export default ForgotPassword;
