import React, { useState } from 'react';
import { Button, ButtonGroup, Spinner } from 'reactstrap';
import Form from '@rjsf/core';
import gqlTag from 'graphql-tag';
import { useMutation } from '@apollo/client';

const RESET_PASSWORD = gqlTag`
  mutation ResetPassword($userId: Int!, $token: String!, $password: String!) {
    resetPassword(
      input: { userId: $userId, resetToken: $token, newPassword: $password }
    ) {
      success
    }
  }
`;

const schema = {
  title: 'Reset password',
  type: 'object',
  required: ['password', 'confirmPassword'],
  properties: {
    password: {
      type: 'string',
      minLength: 8,
      maxLength: 300,
      title: 'New password',
    },
    confirmPassword: {
      type: 'string',
      minLength: 8,
      maxLength: 300,
      title: 'Confirm new password',
    },
  },
};

const uiSchema = {
  password: {
    'ui:widget': 'password',
  },
  confirmPassword: {
    'ui:widget': 'password',
  },
};

const validate = (formData, errors) => {
  if (formData.password !== formData.confirmPassword) {
    errors.confirmPassword.addError("Passwords don't match");
  }
  return errors;
};

const SetPassword = ({ resetToken, resetUserId, clearResetParams }) => {
  const [passwordChanged, setPasswordChanged] = useState(false);

  const [resetPassword, { loading, error }] = useMutation(RESET_PASSWORD, {
    onCompleted: () => {
      setPasswordChanged(true);
    },
  });

  const onSubmit = data => {
    resetPassword({
      variables: {
        password: data.formData.password,
        token: resetToken,
        userId: +resetUserId,
      },
    }).catch(e => console.error(e));
  };

  return (
    <>
      {error && <p>{error.message || 'Uncaught error'}</p>}
      <hr />
      {passwordChanged ? (
        <>
          <h4 className="mb-4">
            Your password has been changed. Try to sign in with a new password.
          </h4>
          <ButtonGroup className="mr-4">
            <Button onClick={() => clearResetParams()}>Login</Button>
          </ButtonGroup>
        </>
      ) : (
        <Form
          schema={schema}
          uiSchema={uiSchema}
          showErrorList={false}
          validate={validate}
          onSubmit={onSubmit}
          disabled={loading}
        >
          <ButtonGroup className="mr-4">
            <Button disabled={loading} type="submit">
              {loading && (
                <>
                  <Spinner size="sm" />{' '}
                </>
              )}
              Set password
            </Button>
          </ButtonGroup>
          <ButtonGroup>
            <Button
              onClick={() => clearResetParams()}
              disabled={loading}
              outline
            >
              Cancel
            </Button>
          </ButtonGroup>
          {loading && <Spinner size="sm" />}
        </Form>
      )}
    </>
  );
};

export default SetPassword;
