import React, { useState } from 'react';
import gqlTag from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { Jumbotron, Container, ButtonGroup, Button } from 'reactstrap';
import Form from '@rjsf/core';
import { setToken, useToken } from '../../auth/getToken';
import ForgotPassword from './forgotPassword';
import SetPassword from './setPassword';

const LOGIN = gqlTag`
  mutation login($username: String!, $password: String!) {
    login(input: { username: $username, password: $password }) {
      jwtToken
    }
  }
`;

const schema = {
  title: 'Login',
  type: 'object',
  required: ['username', 'password'],
  properties: {
    username: {
      type: 'string',
      minLength: 1,
      maxLength: 300,
      title: 'Login',
      // description: '',
    },
    password: {
      type: 'string',
      minLength: 1,
      maxLength: 300,
      title: 'Password',
      // description: '',
    },
  },
};

const uiSchema = {
  password: {
    'ui:widget': 'password',
  },
};

const Login = ({ message, description }) => {
  const { resetToken, resetUserId, clearResetParams } = useToken();
  const [forgotPassword, setForgotPassword] = useState(false);
  const enterNewPassword = resetToken && resetUserId;

  const [login, { data, error: errorLogin }] = useMutation(LOGIN, {
    onCompleted: data => {
      const {
        login: { jwtToken },
      } = data;
      if (jwtToken !== null) {
        setToken(jwtToken);
        window.location.reload();
      }
    },
  });

  let nullTokenError;

  if (data && data.login.jwtToken === null) {
    nullTokenError = 'Wrong username or password';
  }

  const onSubmit = async data => {
    try {
      login({
        variables: { ...data.formData },
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div>
      <Jumbotron className="m-0" style={{ height: '100vh' }}>
        <Container>
          {!(forgotPassword || enterNewPassword) && (
            <>
              {errorLogin && <div>{errorLogin.message}</div>}
              {message && <div>{message}</div>}
              {nullTokenError && <div>{nullTokenError}</div>}
              <hr />
              <Form
                schema={schema}
                uiSchema={uiSchema}
                showErrorList={false}
                onSubmit={onSubmit}
              >
                <div className="d-flex justify-content-between">
                  <ButtonGroup>
                    <Button type="submit">Log in</Button>
                  </ButtonGroup>
                  <ButtonGroup>
                    <Button
                      className="border-0"
                      onClick={() => setForgotPassword(true)}
                      outline
                    >
                      Forgot password?
                    </Button>
                  </ButtonGroup>
                </div>
              </Form>
            </>
          )}
          {forgotPassword && (
            <ForgotPassword setForgotPassword={setForgotPassword} />
          )}
          {enterNewPassword && (
            <SetPassword
              resetToken={resetToken}
              resetUserId={resetUserId}
              clearResetParams={clearResetParams}
            />
          )}
        </Container>
      </Jumbotron>
    </div>
  );
};

export default Login;
