import axios from '../services/axios';

let http;
let baseUrl;

export const setBaseUrl = _baseUrl => {
  http = axios(_baseUrl);
  baseUrl = _baseUrl;
};

export const getBaseUrl = () => baseUrl;

export const setStartPageTemplate = async (
  deviceId,
  templateId,
  isDefault = false,
) => {
  const response = await http.put(`/devices/${deviceId}/start_page`, {
    templateId,
    isDefault,
  });
  if (response.data.error) {
    throw new Error(response.data.error);
  }
  return response.data;
};

export const getStartPages = async deviceId => {
  const response = await http.get(`/devices/${deviceId}/start_page`);
  if (response.data.error) {
    throw new Error(response.data.error);
  }
  return response.data;
};
