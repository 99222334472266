import axios from '../services/axios';

let http;
let baseUrl;

export const setBaseUrl = _baseUrl => {
  http = axios(_baseUrl);
  baseUrl = _baseUrl;
};

export const getBaseUrl = () => baseUrl;

export const accessSystemDevice = {
  get: async (propertyId, deviceId, accessSystemConfigId) => {
    const response = await http.get(
      `/properties/${propertyId}/devices/${deviceId}/access_system_configs/${accessSystemConfigId}`,
    );
    if (response.data.error) {
      throw new Error(response.data.error);
    }
    return response.data;
  },
  set: async (propertyId, deviceId, accessSystemConfigId, data) => {
    const response = await http.post(
      `/properties/${propertyId}/devices/${deviceId}/access_system_configs/${accessSystemConfigId}`,
      data,
    );
    if (response.data.error) {
      throw new Error(response.data.error);
    }
    return response.data;
  },
  delete: async (propertyId, deviceId, accessSystemConfigId) => {
    const response = await http.delete(
      `/properties/${propertyId}/devices/${deviceId}/access_system_configs/${accessSystemConfigId}`,
    );
    if (response.data.error) {
      throw new Error(response.data.error);
    }
    return response.data;
  },
  list: async (propertyId, deviceId, accessSystemConfigId) => {
    const response = await http.get(
      `/properties/${propertyId}/devices/${deviceId}/access_system_configs`,
    );
    if (response.data.error) {
      throw new Error(response.data.error);
    }
    return response.data;
  },
};
