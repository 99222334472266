import React from 'react';
import {
  ApolloProvider,
  ApolloClient,
  ApolloLink,
  HttpLink,
  split,
} from '@apollo/client';
import gqlTag from 'graphql-tag';

import { ProtectedArea } from '../auth/protectedArea';
import { onError } from '@apollo/client/link/error';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { InMemoryCache } from '@apollo/client/cache';
import { getToken } from '../auth/getToken';

const createClient = graphqlServerUrl => {
  const webSocketEndpointURL = graphqlServerUrl.replace(
    /^http(s{0,1}):/i,
    'ws$1:',
  );

  const wwbSocketLink = new WebSocketLink({
    uri: webSocketEndpointURL,
    options: {
      reconnect: true,
      connectionParams: {
        Authorization: `Bearer ${getToken()}`,
      },
    },
  });

  const cache = new InMemoryCache();

  const auth = (operation, next, token) => {
    if (
      operation.operationName === 'login' ||
      operation.operationName === 'ForgotPassword' ||
      operation.operationName === 'ResetPassword'
    ) {
      return next(operation);
    }

    operation.setContext(context => ({
      ...context,
      headers: {
        ...context.headers,
        ...(token ? { Authorization: `Bearer ${token}` } : {}),
      },
    }));
    return next(operation);
  };
  return new ApolloClient({
    link: ApolloLink.from([
      (operation, next) => auth(operation, next, getToken()),
      onError(({ networkError }) => {
        if (networkError && 'statusCode' in networkError) {
          const code = networkError.statusCode;
          cache.writeQuery({
            query: gqlTag`
              query NetworkError {
                networkError {
                  code
                }
              }
            `,
            data: { networkError: { code } },
          });
        }
      }),
      split(
        // split based on operation type
        ({ query }) => {
          const definition = getMainDefinition(query);
          return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
          );
        },
        wwbSocketLink,
        new HttpLink({
          uri: graphqlServerUrl,
          fetch,
        }),
      ),
    ]),
    cache,
    resolvers: {},
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
      },
    },
  });
};

export const ApolloInit = ({ element, graphqlServerUrl }) => {
  const client = createClient(graphqlServerUrl);
  return (
    <ApolloProvider client={client}>
      <ProtectedArea>{element}</ProtectedArea>
    </ApolloProvider>
  );
};
