import { useState, useEffect } from 'react';
import { changeRole } from './ability';

const { default: jwtDecode } = require('jwt-decode');
const URL_TOKEN_KEY = 'token';
const URL_RESET_TOKEN_KEY = 'reset_token';
const URL_RESET_USER_ID_KEY = 'user_id';
const STORAGE_TOKEN_KEY = 'userToken';

const clearTokenFromHistory = (token, key) => {
  window.history.replaceState(
    window.history.state,
    '',
    window.location.href.replace(`${key}=${token}`, ''),
  );
};

const getParamFromLocation = key => {
  if (typeof window === 'undefined') return undefined;
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has(key)) {
    return urlParams.get(key);
  }
  return undefined;
};

class TokenService {
  constructor() {
    this.listeners = [];
  }
  getToken() {
    if (typeof window !== 'undefined') {
      return window.localStorage[STORAGE_TOKEN_KEY];
    }
  }
  setToken(token) {
    window.localStorage[STORAGE_TOKEN_KEY] = token;
    this._callListeners(token);
  }
  clear() {
    window.localStorage.removeItem(STORAGE_TOKEN_KEY);
    this._callListeners(undefined);
  }
  getUser() {
    const token = this.getToken();
    if (!token) return {};
    try {
      const decodedToken = jwtDecode(token);
      const { role, user_id, username } = decodedToken;
      return { role, userId: +user_id, username };
    } catch (error) {
      return { error };
    }
  }
  addListener(func) {
    this.listeners.push(func);
    return () => this.removeListener(func);
  }
  removeListener(func) {
    this.listeners = this.listeners.filter(val => val !== func);
  }
  _callListeners(updatedToken) {
    this.listeners.forEach(func => {
      func(updatedToken);
    });
  }
}

const tokenService = new TokenService();

changeRole(tokenService.getUser());
tokenService.addListener(() => {
  changeRole(tokenService.getUser());
});

export const setupTokenFromLocation = () => {
  const tokenFromLocation = getParamFromLocation(URL_TOKEN_KEY);
  if (tokenFromLocation) {
    tokenService.setToken(tokenFromLocation);
    clearTokenFromHistory(tokenFromLocation, URL_TOKEN_KEY);
  }
};

export const clearToken = () => tokenService.clear();
export const getToken = () => tokenService.getToken();
export const setToken = token => tokenService.setToken(token);

export const useToken = () => {
  const [token, setToken] = useState(tokenService.getToken());
  const [userFromToken, setUserFromToken] = useState(tokenService.getUser());
  const [resetToken, setResetToken] = useState(null);
  const [resetUserId, setResetUserId] = useState(null);

  const clearResetParams = () => {
    setResetToken(null);
    setResetUserId(null);
  };

  useEffect(() => {
    return tokenService.addListener(updatedToken => {
      setToken(updatedToken);
      setUserFromToken(tokenService.getUser());
    });
  }, []);

  useEffect(() => {
    const resetTokenFromLocation = getParamFromLocation(URL_RESET_TOKEN_KEY);
    const resetUserIdFromLocation = getParamFromLocation(URL_RESET_USER_ID_KEY);
    if (resetTokenFromLocation && resetUserIdFromLocation) {
      setResetToken(resetTokenFromLocation);
      setResetUserId(resetUserIdFromLocation);
      // Clear reset params from history
      window.history.replaceState(null, null, window.location.pathname);
    }
  }, []);

  return {
    token,
    userFromToken,
    resetToken,
    resetUserId,
    clearResetParams,
  };
};

export default getToken;
