import React, { useEffect, useState } from 'react';
import gqlTag from 'graphql-tag';
import { useQuery, useApolloClient } from '@apollo/client';
import { Spinner } from 'reactstrap';
import { ability } from './ability';
import { clearToken, useToken } from './getToken';
import { AbilityContext } from './abilityContext';
import { isApolloError } from '@apollo/client';
import Login from '../components/login';

export const UserContext = React.createContext({});

const GET_CURRENT_USER = gqlTag`
  {
    currentUser {
      name
      id
      role
    }
  }
`;

const GET_NETWORK_ERROR = gqlTag`
  query networkError {
    networkError @client {
      code
    }
  }
`;

const useCurrentUser = () => {
  const { userFromToken } = useToken();
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const client = useApolloClient();

  useEffect(() => {
    const fetchUser = async () => {
      setLoading(true);
      try {
        const response = await client.query({ query: GET_CURRENT_USER });
        if (response.error) setError(response.error);
        if (response.data) setCurrentUser(response.data.currentUser);
      } finally {
        setLoading(false);
      }
    };

    if (!userFromToken.userId) {
      setCurrentUser(null);
    } else {
      fetchUser();
    }
  }, [userFromToken, client]);

  return { currentUser, loading, error };
};

const useNetworkError = () => {
  const { data } = useQuery(GET_NETWORK_ERROR);

  let errorMessage;

  useEffect(() => {
    if (data && data.networkError) {
      const code = data.networkError.code;
      if (code === 401 || code === 403) {
        clearToken();
      }
    }
  }, [data]);

  if (data && data.networkError) {
    const code = data.networkError.code;
    if (code === 401) {
      errorMessage = 'Session has been expired. Try sign in again';
    }
    if (code === 403) {
      errorMessage = 'Access denied';
    }
  }

  return errorMessage;
};

export const ProtectedArea = props => {
  const { userFromToken } = useToken();

  const { currentUser, loading, error: currentUserError } = useCurrentUser();
  const networkError = useNetworkError();

  let errorMessage;

  if (currentUserError) {
    errorMessage = 'Uncaught error';
    if (isApolloError(currentUserError)) {
      errorMessage = currentUserError.message;
    }
  }

  if (userFromToken.error) errorMessage = 'Invalid token';
  if (networkError) errorMessage = networkError;

  const userData = { ...userFromToken };
  if (currentUser) userData.currentUser = currentUser;

  const authenticated =
    userFromToken.userId && !currentUserError && currentUser;

  return (
    <AbilityContext.Provider value={ability}>
      {authenticated && (
        <UserContext.Provider value={userData}>
          {props.children}
        </UserContext.Provider>
      )}
      {loading && <Spinner color="secondary" />}
      {!authenticated && !loading && <Login message={errorMessage} />}
    </AbilityContext.Provider>
  );
};
