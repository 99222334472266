import axios from '../services/axios';

let http;
let baseUrl;

export const setBaseUrl = _baseUrl => {
  http = axios(_baseUrl);
  baseUrl = _baseUrl;
};

export const getBaseUrl = () => baseUrl;

export const paymentSystemDevice = {
  totals: async paymentSystemDeviceId => {
    const response = await http.post(
      `/payment_system_devices/${paymentSystemDeviceId}/totals`,
      {},
    );
    if (response.data.error) {
      throw new Error(response.data.error);
    }
    return response.data;
  },
  reboot: async paymentSystemDeviceId => {
    const response = await http.post(
      `/payment_system_devices/${paymentSystemDeviceId}/reboot`,
      {},
    );
    if (response.data.error) {
      throw new Error(response.data.error);
    }
    return response.data;
  },
  openPeriod: async paymentSystemDeviceId => {
    const response = await http.post(
      `/payment_system_devices/${paymentSystemDeviceId}/open_period`,
      {},
    );
    if (response.data.error) {
      throw new Error(response.data.error);
    }
    return response.data;
  },
  closePeriod: async paymentSystemDeviceId => {
    const response = await http.post(
      `/payment_system_devices/${paymentSystemDeviceId}/close_period`,
      {},
    );
    if (response.data.error) {
      throw new Error(response.data.error);
    }
    return response.data;
  },
  maintenance: async (paymentSystemDeviceId, timeout) => {
    const response = await http.post(
      `/payment_system_devices/${paymentSystemDeviceId}/maintenance`,
      { timeout },
    );
    if (response.data.error) {
      throw new Error(response.data.error);
    }
    return response.data;
  },
};
