import axios from '../services/axios';

let http;

export const setBaseUrl = baseUrl => {
  http = axios(baseUrl);
};

export const getDevices = async (limit, skip) => {
  const response = await http.get('/devices', { params: { skip, limit } });
  if (response.data.error) {
    throw new Error(response.data.error);
  }
  if (response.data.data === undefined) {
    throw new Error('"data" key is undefined');
  }
  return response.data;
};

export const getDeviceConfig = async id => {
  const response = await http.get('/device_config_get', { params: { id } });
  if (response.data.error) {
    throw new Error(response.data.error);
  }
  return response.data;
};

export const updateDeviceConfig = async (id, data) => {
  const response = await http.post('/device_config_set', data, {
    params: { id },
  });
  if (response.data.error) {
    throw new Error(response.data.error);
  }
  return response.data;
};
