import { Ability, AbilityBuilder, createAliasResolver } from '@casl/ability';

const resolveAction = createAliasResolver({
  modify: ['load', 'update', 'read', 'view', 'create', 'delete'],
});

export const ability = new Ability([], {
  resolveAction,
});

export const changeRole = user => {
  ability.update(defineRulesFor(user));
};

export const defineRulesFor = user => {
  const { can, cannot, rules } = new AbilityBuilder();
  if (user.role === 'sezam24_admin') {
    can('modify', 'all');
    cannot('update', 'Hardware', ['id']);
    cannot('update', 'MessagingTemplate', 'id');
    cannot('update', 'Area');
    can('update', 'Area', [
      'accessSystemConfigId',
      'attributes',
      'attributes.roomId',
      'attributes.customAttributes',
    ]);
  }
  if (user.role === 'sezam24_manager') {
    can('modify', 'all');
    cannot('load', 'PropertiesList');
    cannot('update', 'PropertyInformation', ['propertyTypeId', 'status']);
    cannot('update', 'UpdatePaymentSystemDevice');
    cannot('update', 'Area');
    can('update', 'Area', [
      'accessSystemConfigId',
      'attributes',
      'attributes.roomId',
      'attributes.customAttributes',
    ]);
    cannot('modify', ['Hardware']);
    cannot('update', 'MessagingTemplate', 'id');
  }
  if (user.role === 'sezam24_distributor') {
    can('modify', 'all');
    cannot('load', 'PropertiesList');
    cannot('update', 'PropertyInformation', ['propertyTypeId']);
    cannot('update', 'Settings', 'preauth');
    cannot('update', 'PreauthConfig');
    cannot('update', 'Area');
    can('update', 'Area', [
      'accessSystemConfigId',
      'attributes',
      'attributes.roomId',
      'attributes.customAttributes',
    ]);
    cannot('update', 'Hardware', [
      'id',
      'serialReference',
      'typeId',
      'deviceId',
      'macSerial',
      'vpnClientAddr',
    ]);
    cannot('modify', 'Hardware', 'deviceConfig');
    cannot('modify', 'Hardware', [
      'hwId',
      'distributorId',
      'hddPassword',
      'sslCaCert',
      'sslClientCert',
      'sslClientKey',
      'vpnServerName',
      'vpnConfig',
      'kioskHwSrvConfig',
      'kioskDefConfig',
    ]);
    cannot('modify', [
      'booking-sets',
      'guests',
      'payments',
      'preauth',
      'payment-system-devices',
    ]);
  }
  if (user.role === 'sezam24_receptionist') {
    can('read', 'all');
    can('view', 'all');
    cannot('load', 'PropertiesList');
    cannot('modify', 'PmsConfig');
    cannot('modify', 'PreauthConfig');
    cannot('modify', [
      'MessagingTemplate',
      'Hardware',
      'PmsConfig',
      'PreauthConfig',
      'pms-config',
      'preauth-config',
      'devices',
      'payment-system-devices',
      'payment-networks',
    ]);
    cannot('view', 'messaging');
    cannot('update', 'UpdateDesign');
  }
  if (user.role !== 'sezam24_admin') {
    cannot('modify', 'Users');
    cannot('modify', 'PmsConfig', ['syncSwitch', 'sync']);
    // cannot('update', 'UpdateDesign');
  }
  return rules;
};

export default ability;
