import React from 'react';
import { Jumbotron, Container } from 'reactstrap';

const GlobalMessage = ({ message, description }) => {
  return (
    <div>
      <Jumbotron className="m-0" style={{ height: '100vh' }} fluid>
        <Container fluid>
          <h1 className="display-4">{message}</h1>
          <p className="lead">{description}</p>
        </Container>
      </Jumbotron>
    </div>
  );
};

GlobalMessage.defaultProps = {
  message: 'Error',
  description: 'Please contact an administrator if this problem persists',
};

export default GlobalMessage;
