/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
const React = require('react');
const { Main } = require('./src/main');

exports.wrapRootElement = ({ element }) => {
  return <Main element={element} />;
};
