import React, { useState, useEffect } from 'react';
import { Spinner } from 'reactstrap';
import GlobalMessage from './components/containers/globalMessage';
import { ApolloInit } from './services/apolloInit';
import { setBaseUrl as setConfigurationsServerUrl } from './api/configurations';
import { setBaseUrl as setKioskUiApiUrl } from './api/kioskUiApi';
import { setBaseUrl as setDatastoreUrl } from './api/datastore';
import { setBaseUrl as setTaskerUrl } from './api/tasker';
import { setupTokenFromLocation } from './auth/getToken';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/global.css';

export const Config = React.createContext({});

setupTokenFromLocation();

export const Main = ({ element }) => {
  const [config, setConfig] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const response = await fetch('/api.json');
        setConfig(await response.json());
      } catch (e) {
        console.error('Error on fetching /api.json', e);
        setError(e);
      }
    };
    fetchConfig();
  }, []);

  if (error) {
    return <GlobalMessage message="Error on fetching /api.json" />;
  }
  if (config) {
    setConfigurationsServerUrl(config.configurationsServerUrl);
    setKioskUiApiUrl(config.kioskUiApiUrl);
    setDatastoreUrl(config.datastoreUrl);
    setTaskerUrl(config.taskerUrl);
    return (
      <ApolloInit
        element={element}
        graphqlServerUrl={config.graphqlServerUrl}
      />
    );
  }
  return <Spinner color="secondary" />;
};
