import axios from 'axios';
import { getToken } from '../auth/getToken';

const getHeaders = () => {
  const token = getToken();
  return {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    ...(token ? { Authorization: `Bearer ${token}` } : {}),
  };
};

const createAxios = baseURL =>
  axios.create({
    baseURL,
    headers: getHeaders(),
    withCredentials: true,
  });

export default createAxios;
